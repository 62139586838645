import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Contact = () => (
  <Layout>
    <div className='strip strip-blog strip-blog-page'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-8'>
            <span className='section-title mb-3'>Want to say Hi? </span>
            <form
              action='https://getform.io/f/84c8ac35-3665-4694-8d7c-62145542830f'
              method='POST'
              data-netlify='true'
            >
              <h3 className='mb-3'>
                I am open for freelance projects, web consulting, or even just
                say Hi! 👋
              </h3>
              <div className='form-group'>
                <input
                  type='text'
                  name='name'
                  placeholder='Your Name'
                  className='form-control'
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  name='email'
                  placeholder='Your Email'
                  className='form-control'
                />
              </div>
              <div className='form-group'>
                <textarea
                  name='message'
                  placeholder="Let's talk about your idea"
                  className='form-control'
                  rows={4}
                />
              </div>
              <div
                className='g-recaptcha'
                data-sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              />
              <button
                type='submit'
                className='button button-secondary btn-as-link'
              >
                Submit
              </button>
            </form>
            <br />
            <p>
              Unlock deeper insights into my expertise and discover how I can
              assist you by clicking{' '}
              <Link to='/about' className='contact-link'>
                here
              </Link>
              . Explore my comprehensive profile and connect with me for
              consulting services through{' '}
              <a
                href='https://www.upwork.com/freelancers/~01670327b31b02c76e'
                className='contact-link'
              >
                Upwork
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const Head = () => <SEO title='Contact' />;

export default Contact;
